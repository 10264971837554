import React from 'react'
import Layout from '../components/Layout'
import {graphql} from 'gatsby'
import CategoriesWidget from '../components/Blog/CategoriesWidget'
import RecentPostsWidget from '../components/Blog/RecentPostsWidget'
import PostEntry from '../components/Blog/PostEntry'
import SEO from '../components/SEO'
import PageHeader from '../components/Layout/PageHeader'
import {Inner} from '../components/styles'
import {
  PageLayout,
  MainContent,
  Sidebar,
  StickySidebarContent,
} from '../components/Blog/Layout'

const WordPressCategory = props => {
  const {
    location,
    data: {
      wpgraphql: {category},
    },
  } = props

  return (
    <Layout location={location} background="#fafafa">
      <SEO location={location} title={`${category.name}`} />
      <PageHeader location={location}>{category.name}</PageHeader>

      <Inner>
        <PageLayout>
          <MainContent>
            {category.posts.nodes &&
              category.posts.nodes.map(post => (
                <PostEntry key={post.id} post={post} />
              ))}
          </MainContent>
          <Sidebar>
            <StickySidebarContent>
              <CategoriesWidget />
              <RecentPostsWidget />
            </StickySidebarContent>
          </Sidebar>
        </PageLayout>
      </Inner>
    </Layout>
  )
}

export default WordPressCategory

export const pageQuery = graphql`
  query GET_CATEGORY($id: ID!) {
    wpgraphql {
      category(id: $id) {
        id
        name
        slug
        posts(first: 100) {
          nodes {
            ...PostEntryFragment
          }
        }
      }
    }
  }
`
